.slider-container-featured {
    width: 500px;
    height: 500px;
    overflow: scroll;

  }
  
  .slider-featured {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    width: fit-content;
    height: 100%;
    cursor: grab;
  }
  
  .slide-featured {
    width: 500px;
    height: 200px;
    scroll-snap-align: start;
    margin-right: 20px;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
  }
  .home-title{
  text-align: center;
  font-size: 1.2rem;
  line-height: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  font-family: Poppins,sans-serif;
}
@media (min-width: 1024px){
.home-title {
    text-align: start;
    font-size: 1.875rem;
    line-height: 2.25rem;
}}
.home-subtitle, .hostel-title{
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 600;
  font-family: Poppins,sans-serif;
  margin: 0px;
  color: black;
}
