.bottombar_wrapper {
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  height: 70px;
  background-color: white;
  position: fixed;
  bottom: 0px;
  z-index: 999;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
  justify-content: space-around;
}
@media only screen and (min-width: 768px) {
  .bottombar_wrapper {
    padding: 1.25rem;
  }
}
.bottm_menu_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100px;
  align-items: center;
  height: 100%;
  cursor: pointer;
}
.bottm_menu_li {
  margin: 0px;
  font-family: Mulish, sans-serif !important;
  font-size: 0.65rem !important;
  line-height: 1.5rem;
  font-weight: 700;
  color: grey;
  text-transform: uppercase;
}
