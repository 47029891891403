/* ImageSlider.css */
.slider-container-imageviewer {
    position: relative;
    overflow: hidden;
    width: 1200px;
    margin: auto;
    max-width: 90vw;
    margin-top: 1rem;
  }
  
  .slider {
    display: flex;
    height: 70vh; /* Set the slider height to fill the viewport */
    border-radius: 19px;
    max-height: 640px;
  }
  
  .slide {
    flex: 0 0 100%; /* Each slide takes up the full width of the container */
    height: 70vh;
    background-size: cover;
    background-position: center;
    transition: transform 0.5s;
    border-radius: 19px;
    max-height: 640px;
  }
  @media only screen and (max-width: 699px){
    .slider {
      height: 300px; /* Set the slider height to fill the viewport */
    }
    
    .slide {
      height: 300px;
    }
  }
  .dots {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .dot-img-container {
    padding-top: 1rem;
    display: inline-block;
    margin: 0 5px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .dot-img{
    width: 130px;
    overflow: hidden;
    height: auto;
    border: 1px solid white;
    border-radius: 5px;
  }
  .dot-img-active {
    border: 1px solid #ffe700;
  }
  .right_arrow_gall{
    position: absolute;
    right: 5px;
    width: 50px;
    height: 50px;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
    color: white;
    border-radius: 50%;
    bottom: 50%;
    cursor: pointer;
  }
  .left_arrow_gall {
    position: absolute;
    left: 5px;
    width: 50px;
    height: 50px;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
    color: white;
    border-radius: 50%;
    bottom: 50%;
    cursor: pointer;
  }
  .imageviewer_header{
    flex-wrap: nowrap;
    justify-content: center;
  }
  .icon_wrapper_viewr{
    width: fit-content;
    cursor: pointer;
  }