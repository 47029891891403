.whatsapp_wrapper{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: fixed;
    top: 80vh;
    right: 100px;
    background-color: #ffe700;
    z-index: 9999;
    justify-content: center;
    align-items: center;
}
@media only screen and (max-width: 1024px){
    .whatsapp_wrapper {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        position: fixed;
        top: 80vh;
        right: 15px;
        background-color: #ffe700;
        z-index: 9999;
        justify-content: center;
        align-items: center;
}}