/* ImageSlider.css */
.slider-container {
  position: relative;
  overflow: hidden;
  /* width: 1200px; */
  margin: auto;
  max-width: 95vw;
}

.slider {
  display: flex;
  height: 70vh; /* Set the slider height to fill the viewport */
  border-radius: 19px;
  max-height: 640px;
}

.slide {
  flex: 0 0 100%; /* Each slide takes up the full width of the container */
  height: 70vh;
  background-size: cover;
  background-position: center;
  transition: transform 0.5s;
  border-radius: 19px;
  max-height: 640px;
}
@media only screen and (max-width: 699px){
  .slider {
    height: 300px; /* Set the slider height to fill the viewport */
  }
  
  .slide {
    height: 300px;
  }
}
.dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: #ccc;
  cursor: pointer;
}

.dot.active {
  background-color: #ffe700;
}
