.footer{
    background-color: black;
    min-height: fit-content;
    margin-top: 3rem;
    overflow: hidden;
}
.footer_header{
    border-bottom: 1px solid white;
    width: 100%;
    height: 80px;
    margin: 0px;
}
.footer_bttm_footer{
    width: 100%;
    height: 80px;
    margin: 0px;
}
.social_logo_footer{
    width: fit-content;
}
.title_menu_footer_title{
    font-family: Poppins,sans-serif;
    color: #ffe700;
    margin-bottom: 10px !important;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 700;
}
.title_menu_footer_li{
    font-family: Poppins,sans-serif;
    margin: 0px;
    margin-top: 8px;
    font-size: 0.9rem;
    line-height: 1.2rem;
    color: white;
    font-weight: 600;
    cursor: pointer;
}
.footer_bttm_footer{
    font-family: Poppins,sans-serif;
    font-size: 1rem;
    align-items: center;
    justify-content: left;
    font-weight: 600;
    justify-content: space-between;
    display: flex;
}
.footer_bttm_footer p{
    margin-bottom: 0px;
    font-size: 0.8rem;
}
.footer_bttm_footer a{
 text-decoration: underline;
 color: white;
}
.footer_container{
    width: 100%;
    max-width: 1280px;
    display: flex;
    padding: 0 50px;
    margin: 0px auto;
}
.footer_logo{
    width: 180px;
}
@media only screen and (max-width: 1024px){
    .title_menu_footer_title{
        font-size: 0.8rem;
        line-height: 1.5rem;
    }
    .title_menu_footer_li{
        font-size: 0.7rem;
        line-height: 1.2rem;
    }
    .footer_bttm_footer{
        font-size: 0.8rem;
    }
    .footer_bttm_footer p{
        font-size: 0.7rem;
    }
    .footer{
        margin:auto auto 70px auto;
    }
    .footer_logo{
        width: 130px;
    }
    .footer_bttm_footer a{
     text-decoration: underline;
     color: white;
}   }
