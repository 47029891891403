.drawer_wrapper {
  padding: 20px 0;
  border-right: 1px;
  width: 70vw;
  max-width: 100%;
  background-color: white;
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 2222;
  -webkit-transition: all 0.5s ease-out;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  transition-delay: 0s;
  transition-property: all;
}
.backdrop_faded {
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
