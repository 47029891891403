.thanks_row {
  width: 100%;
  height: 50vh;
  place-content: center;
  text-align: center;
}
.thanks_row h3 {
  font-family: Poppins, sans-serif;
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
}
