.accordian_header {
  background-color: #ffe700 !important;
  font-weight: 600 !important;
  font-family: Poppins, sans-serif !important;
}
.accordian_body {
  background-color: #414141 !important;
  color: white;
}
.accordian_subtitle {
  font-family: Poppins, sans-serif !important;
  font-size: 1rem !important;
  line-height: 1.5rem;
}
.accordian_subtitle_policies {
  font-family: Mulish, sans-serif !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  cursor: pointer;
}
.accordian_title {
  font-weight: 600 !important;
  font-family: Poppins, sans-serif !important;
  font-size: 1rem !important;
  line-height: 1.5rem;
}
.faq_acc,
.faq_acc_main,
.faq_acc_deatail {
  background-color: white !important;
  border: none !important;
}
.faq_acc {
  align-items: center !important;
  justify-content: center;
}
.faq_icon_div {
  background-color: #ffe700;
  color: black;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.faq_acc_deatail {
  margin-left: 2rem;
  height: fit-content;
  padding: 5px 10px !important;
  text-align: left;
}
.faq_acc_content {
  display: flex;
  align-items: center !important;
  text-align: left;
}

.accordian_header_menu {
  background-color: none !important;
  font-weight: 600 !important;
  font-family: Poppins, sans-serif !important;
  box-shadow: none !important;
}
.accordian_body_menu {
  background-color: none !important;
  color: white;
}
.accordian_subtitle_menu {
  font-family: Poppins, sans-serif !important;
  font-size: 1rem !important;
  line-height: 1.5rem;
  color: black;
}
.accordian_subtitle_menu {
  font-family: Mulish, sans-serif !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  cursor: pointer;
}
.accordian_title_menu {
  font-weight: 600 !important;
  font-family: Poppins, sans-serif !important;
  font-size: 1rem !important;
  line-height: 1.5rem;
}
