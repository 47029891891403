.topdest-card {
  overflow: hidden;
  border-radius: 16px;
  position: relative;
  cursor: pointer;
}
.overlay-text-topdest {
  position: absolute;
  bottom: 7px;
  color: white;
  font-size: 20px;
}
.topdest-card-title {
  font-size: 1rem;
  line-height: 1.5rem;
  margin: auto;
  text-transform: uppercase;
  font-family: Poppins, sans-serif;
  padding-left: 1rem;
}
.topdest-sub-title {
  font-size: 1.125rem;
  line-height: 1.25rem;
  margin: auto;
  text-transform: capitalize;
  font-weight: 600;
  font-family: Poppins, sans-serif;
  background-color: #9d9d9d78;
  padding: 0.2rem 0.6rem;
  margin: 0 5px;
  border-radius: 10px;
}

.img_top_card {
  width: auto;
}
.dest-card-small-wrapper {
  border: 5px solid white;
}
