
.header{
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
    transition: all .4s cubic-bezier(.165,.84,.44,1);
    background-color: white;
    color: black !important;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 8px;
}
.menu_button{
    max-width: fit-content;
    height: 70px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    border-bottom: 4px solid transparent;
    text-decoration: none;
}
.menu_button_li_box{
    color: #051137 !important;
    border-radius: 20px;
    background-color: white !important;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    padding: 2px 10px;
    border: 2px solid black;
}
.menu_button_li_box:hover{
    color:white !important;
    background-color: black !important;

}
.menu_button_li{
    color: black !important;
    cursor: pointer;
}
.menu_button:hover{
  border-bottom: 4px solid #ffe700 ;
}
.megamenu_drpdwn{
    background-color: white;
    margin: 10px;
    flex-wrap: inherit;
}
.megamenu_drpdwn .mega_drop_col, .megamenu_drpdwn .mega_drop_col_img{
    /* align-items: center; */
    display: flex;
    flex-direction: column;
}
.mega_drop_col_img{
   max-width: 500px;
   max-height: 415px;
}
.mega_drop_btn_img{
    position: absolute;
    top: 50%;
    font-weight: 400;
    border-radius: 4px;
    background-color: white;
    right: 37%;
    border: none;
    font-size: 16px;
    padding: 4px 16px;
}
.mega_drop_menuitem{
    width: auto;
}
.active_btn_menu_mega{
    border-bottom: 2px solid #ffe700 !important;
    color:black !important ;
    background-color: #f5f5f5 !important;
}
.mega_drop_btn_img:hover{
    border-color: #ffe700;
    background-color: #ffe700!important;
    color: black!important;
}
.header_logo{
    justify-content: center;
}
@media only screen and (max-width: 700px){
    .header_logo{
        justify-content: center;
}}