.collection-card {
  width: 220px;
  overflow: hidden;
  border-radius: 16px;
  position: relative;
  cursor: pointer;
}
.overlay-text-collection {
  position: absolute;
  bottom: 7px;
  left: 10%;
  color: black;
  font-size: 20px;
  padding: 5px 10px;
  background-color: white;
  border-radius: 10px;
  width: 80%;
}
.collection-card-title {
  font-size: 1rem;
  line-height: 1.5rem;
  margin: auto;
  text-transform: uppercase;
  font-family: Poppins, sans-serif;
  text-align: center;
}
.collection-sub-title {
  font-size: 0.8rem;
  line-height: 1.5rem;
  margin: auto;
  text-transform: uppercase;
  font-family: Poppins, sans-serif;
  text-align: center;
}

.collection_wrapper {
  width: 100%;
  max-width: 1280px;
  justify-content: center;
  padding: 0 50px;
  margin: auto;
}
@media only screen and (max-width: 700px) {
  .collection_wrapper {
    padding: 0 10px;
    overflow-x: hidden;
  }
}
.gallery_modal,
.content_gallery_modal {
  background-color: black;
}
