
body {
  margin: 0;
  font-family: 'Mulish', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.MuiButton-text,.mega_drop_menuitem{
  font-family: 'Mulish', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  text-transform:capitalize
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.container{
  width: 100%;
  /* max-width: 1280px; */
  max-width: 1350px;
  display: flex;
  justify-content: center;
  padding: 0 50px;
}
.mob_only,.desktop_only{
  display: none;
}

@media only screen and (max-width: 1023px){
  .mob_only {
    display: flex!important;
}
}

@media only screen and (max-width: 700px){
.container{
  padding: 0 10px;
}
}
@media only screen and (min-width: 1024px){
  .desktop_only {
    display: flex!important;
}
}
.text-align-end{
  text-align: end;
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cursor-pointer{
  cursor: pointer !important;
}
::-webkit-scrollbar-thumb {
  background: #ffe700;
  border-radius: 10px;
}
::-webkit-scrollbar-track {
box-shadow: inset 0 0 5px grey;
border-radius: 10px;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.decoration-none{
  text-decoration: none;
}
.col{
  flex-direction: column;
}
.dot_icon{
  color: #ffe700;
  font-size:3rem;
  padding-left: 1px;
}
.border-theme{
  border: 2px solid #ffe700;
}