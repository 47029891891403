.policies_heading{
    font-family: Poppins,sans-serif;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.875rem;
    line-height: 2.25rem;
    padding-top: 2rem;
}
.ploicies_menu_wrapper{
    background: #ffe700;
    padding: 20px 10px 0 10px;
    border-radius: 5px;
}
.policiy_para_container h3{
    font-family: Poppins,sans-serif;
    font-size: 1.5rem;
}
.policiy_para_container h4{
    font-family: Poppins,sans-serif;
    font-size: 1.3rem;
}
.policiy_para_container p{
    font-family: Poppins,sans-serif;
    font-size: 1rem;
}