.best-selling-wrapper {
  overflow: auto;
  overflow: auto;
  flex: auto;
  flex-wrap: nowrap;
  padding-bottom: 2rem;
}
@media only screen and (max-width: 1279px) {
  .best-selling-wrapper {
    overflow: auto;
    flex: auto;
    flex-wrap: nowrap;
  }
}
.best-selling-card {
  max-width: 350px;
  min-width: 200px;
  height: auto;
  min-height: fit-content;
  overflow: hidden;
  border-radius: 16px 16px 0px 0px;
  position: relative;
  cursor: pointer;
  min-height: 368px;
  max-height: 368px;
}
.overlay-text-best-selling {
  position: absolute;
  bottom: 0;
  color: black;
  font-size: 20px;
  width: 100%;
  background: white;
  /* height: 115px; */
  padding: 10px;
  border-top: none;
  border-radius: 10px 10px 0px 0px;
}
.best-selling-card-title {
  font-size: 1.3rem;
  line-height: 1.5rem;
  margin: auto;
  text-transform: uppercase;
  font-family: Poppins, sans-serif;
  font-weight: 700;
}
.best-selling-sub-title {
  font-size: 0.9rem;
  line-height: 1.5rem;
  margin: auto;
  color: grey;
  text-transform: uppercase;
  font-weight: 500;
  font-family: Poppins, sans-serif;
}
.best-selling-sub-para {
  font-size: 0.9rem;
  line-height: 1.5rem;
  margin: auto;
  color: grey;
  font-weight: 500;
  font-family: Poppins, sans-serif;
}
.ribbon-wrapper {
  width: 85px;
  height: 88px;
  overflow: hidden;
  position: absolute;
  top: -3px;
  left: -3px;
}
.ribbon {
  font-family: Poppins, sans-serif;
  color: #333;
  text-align: center;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  position: relative;
  padding: 5px 0;
  top: 15px;
  left: -30px;
  width: 120px;
  background-color: #ffeb3b;
  color: #fff;
  text-transform: uppercase;
  color: black;
  font-size: 0.7rem;
  line-height: 1.25rem;
  font-weight: 600;
}
.buy_now_btn {
  width: 100px;
  padding: 8px 5px;
  background-color: #ffe700;
  border: none;
  color: black;
  font-size: 12px;
  font-family: Mulish, sans-serif !important;
  font-weight: 700;
  cursor: pointer;
  box-shadow: none;
  border-radius: 5px;
  cursor: pointer;
}
.bestselling_price {
  font-size: 1.5rem;
  line-height: 2.25rem;
  font-family: Mulish, sans-serif !important;
  font-weight: 700;
}
