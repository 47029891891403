.featuredcard-wrapper {
  overflow: auto;
  padding-bottom: 2rem;
}
@media only screen and (max-width: 1279px) {
  .featuredcard-wrapper {
    overflow: auto;
    flex: auto;
    flex-wrap: nowrap;
  }
}
.featured-card {
  width: 230px;
  height: 276px;
  overflow: hidden;
  border-radius: 16px;
  position: relative;
  cursor: pointer;
}
.featured-card img {
  height: inherit;
  min-width: 100%;
}
.overlay-text-featured {
  position: absolute;
  bottom: 7px;
  color: white;
  font-size: 20px;
}
.featured-card-title {
  font-size: 1rem;
  line-height: 1.5rem;
  margin: auto;
  text-transform: uppercase;
  font-family: Poppins, sans-serif;
  padding-left: 1rem;
}
.featured-sub-title {
  font-size: 1.125rem;
  line-height: 1.25rem;
  margin: auto;
  text-transform: capitalize;
  font-weight: 600;
  font-family: Poppins, sans-serif;
  background-color: #9d9d9d78;
  padding: 0.2rem 0.6rem;
  margin: 0 5px;
  border-radius: 10px;
}

.ribbon-wrapper {
  width: 85px;
  height: 88px;
  overflow: hidden;
  position: absolute;
  top: -3px;
  left: -3px;
}
.ribbon {
  font-family: Poppins, sans-serif;
  color: #333;
  text-align: center;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  position: relative;
  padding: 5px 0;
  top: 15px;
  left: -30px;
  width: 120px;
  background-color: #ffeb3b;
  color: #fff;
  text-transform: uppercase;
  color: black;
  font-size: 0.7rem;
  line-height: 1.25rem;
  font-weight: 600;
}
