.blogcards-wrapper {
  overflow: auto;
  flex: auto;
  flex-wrap: nowrap;
  padding-bottom: 2rem;
}
.blog-card {
  height: auto;
  min-height: fit-content;
  overflow: hidden;
  border-radius: 16px;
  position: relative;
  cursor: pointer;
}
.overlay-text-blog {
  color: black;
  font-size: 20px;
  width: 100%;
  background: white;
  height: auto;
  padding-top: 10px;
}
.overlay-read-blog {
  position: absolute;
  top: 10px;
  left: 8px;
  color: white;
  font-size: 20px;
  width: 100px;
  background: rgb(76, 75, 75);
  font-size: 12px;
  height: auto;
  display: flex;
  align-items: center;
  padding: 6px;
  justify-content: center;
  border-radius: 5px;
  font-family: Poppins, sans-serif;
}
.best-selling-card-title {
  font-size: 1.3rem;
  line-height: 1.5rem;
  margin: auto;
  text-transform: uppercase;
  font-family: Poppins, sans-serif;
  font-weight: 700;
}
.blog-subtitle {
  font-size: 0.9rem;
  line-height: 1.5rem;
  margin: auto;
  color: grey;
  font-weight: 500;
  font-family: Poppins, sans-serif;
}
