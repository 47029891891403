.productpage_container {
  overflow-x: hidden;
}
.product-wrapper {
  max-width: 100%;
}
.product-card {
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
}
.product-card-main {
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
}
.overlay-text-product {
  position: absolute;
  bottom: 7px;
  left: 14px;
  color: white;
  font-size: 20px;
}
.product-card-title {
  font-size: 1rem;
  line-height: 1.5rem;
  margin: auto;
  text-transform: uppercase;
  font-family: Poppins, sans-serif;
}
.product-sub-title {
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin: auto;
  text-transform: uppercase;
  font-weight: 600;
  font-family: Poppins, sans-serif;
}

.img_top_card_prod {
  width: 100%;
}
.img_top_card_prod_main {
  width: auto;
  max-height: 650px;
}
.prod-card-small-wrapper {
  border: 5px solid white;
}
.img_icon_prod {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #ffe700;
  border-radius: 0.75rem;
  z-index: 999;
  width: 47px;
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.product_secnd_col {
  display: none;
}
@media only screen and (min-width: 992px) {
  .product_secnd_col {
    display: block;
  }
}
@media only screen and (max-width: 991px) {
  .roduct-card-main {
    height: auto !important;
  }
  .img_top_card_prod_main {
    width: 100%;
  }
}
.btn-img-gall-back {
  width: auto;
  padding: 7px 10px;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: row;
  border-radius: 3px;
}
.back_icon_gall {
  width: fit-content;
  cursor: pointer;
}
.btn-img-gall-back p {
  font-size: 12px;
  margin: 0;
  padding-left: 3px;
}
.header_gall {
  border: none;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
}
.modal-fullscreen .modal-content {
  height: auto;
}
.iamge_view_modal {
  background-color: white;
}
.tour_name_prdct {
  font-size: 2rem;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0px;
  text-align: center;
}
.btn_view_option_wrapper {
  display: none;
  align-items: center;
  justify-content: end;
  padding: 0;
}
.btn_view_option_prdct {
  border-radius: 0.375rem;
  font-size: 18px;
  text-align: center;
  background-color: #ffe700;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  padding: 10px 15px;
  min-width: 215px;
  cursor: pointer;
}
.product_info {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-family: Mulish, sans-serif;
  color: rgb(145, 145, 145);
  text-align: justify;
  margin: 0;
}
.show_more_prodct {
  font-family: Poppins, sans-serif;
  color: black;
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
  text-align: center;
}
.prdct_subheader_wrapper {
  margin-top: 1rem;
  text-align: center;
  margin-bottom: 1rem;
}
.prdct_subheader {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 600;
  font-family: Poppins, sans-serif;
  text-transform: uppercase;
  padding: 0;
}
.prdct_subtitle {
  font-size: 1.1rem;
  line-height: 2rem;
  font-weight: 600;
  font-family: Poppins, sans-serif;
  text-transform: uppercase;
  padding: 0;
  text-align: left;
}
.enjoyit_wrapper {
  border: 1px solid rgb(209, 213, 219);
  overflow: hidden;

  border-radius: 8px;
}
.enjoyit_header {
  font-family: Poppins, sans-serif;
  color: black;
  font-size: 16px;
  cursor: pointer;
  font-weight: 600;
  margin: 0;
}
.enjoyit_wrapper_main {
  flex-wrap: wrap;
  justify-content: center;
}
.enjoyit_para {
  font-family: Poppins, sans-serif;
  color: rgb(71, 70, 70);
  font-size: 16px;
  cursor: pointer;
  margin: 0;
}
.prdct_para_meet_body {
  font-family: Poppins, sans-serif;
  color: rgb(71, 70, 70);
  font-size: 16px;
  cursor: pointer;
  text-align: left;
}
.prdct_para_meet_title {
  font-family: Poppins, sans-serif;
  color: rgb(71, 70, 70);
  font-size: 18px;
  cursor: pointer;
  font-weight: 600;
}
.enjoyit_header_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.summary_prdct {
  height: 390px;
  overflow: auto;
}
@media (min-width: 1024px) {
  .btn_view_option_wrapper {
    display: flex;
  }
  .prdct_subheader_wrapper {
    text-align: start;
  }
  .tour_name_prdct {
    font-size: 3rem;
    text-align: left;
  }
  .product_info {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .prdct_subheader_wrapper {
    text-align: left;
  }
  .prdct_subheader {
    font-size: 1.75rem;
  }
  .enjoyit_wrapper_main {
    flex-wrap: nowrap;
  }
}

.video_wrapper_product{
  max-width: 100%;
  border-radius: 8px;
  border: 2px solid #7f7d7d;
}
@media only screen and (min-width: 768px) {
  .enjoyit_wrapper_main {
    flex-wrap: nowrap;
  }
  .enjoyit_wrapper {
    margin: 0 7px;
  }
}
@media only screen and (max-width: 767px) {
  .enjoyit_wrapper {
    margin: 0px;
    width: 45%;
    margin: 0 5px;
  }
  .enjoyit_wrapper_main {
    justify-content: space-around;
  }
  .summary_prdct {
    height: 200px;
    overflow: auto;
  }
}
